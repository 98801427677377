/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 
*/
import React , { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import HeaderImage from '../../../assets/img/niec-logo/header.png'

function LandingPageHeader(props) {
  // console.log('ppp', props.datakonten.pic1)
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 8) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 9;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
        <Carousel>
        <Carousel.Item className="responsiveImg">
          <img src={HeaderImage}/>
          <Carousel.Caption>
          <div className="comingsoon">
              <div className="comingsoon-inner text-center">
              </div>
            </div>
            <a href="#register" className="btn btn-register btn-danger fontButton">DAFTAR SEKARANG</a>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default LandingPageHeader;
