// dev
// const superStudent = 'http://103.41.205.87:80';

// prod
const superStudent = 'https://superstudent.niecindonesia.com';


const ambassadorKey = 'Y3G4gG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV';



const API_URL = {
    superStudent,
    ambassadorKey
}

export default API_URL;

// export default api_url;