import React, { Component } from "react";
import axios from 'axios';
import API_URL from  './../../../ApiUrl';
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Alert
} from "reactstrap";
import { LandingPageHeader, DemoFooter, Info } from './../../../components/molecules/';
import Swal from 'sweetalert2';
import SweetAlert from 'react-bootstrap-sweetalert';

class LandingPage extends Component {
  state = {
    nama_lengkap:"",
    no_telp:"",
    email:"",
    id_propinsi:"",
    id_kabkota:"",
    nama_pimpinan:'',
    jabatan:'',
    alamat:'',
    message:"Error !",
    error: {},
    showSwal: false,
    showSalError: false,
    formAlert:{display:'none'},
    formInstitution: 'none',
    formAlertClassName:'alert alert-danger',
    inputBorder: "border: 1px solid #ca0b0b",
    btnDisabled: false,
    btnText: 'Send',
    dataPropinsi: [],
    dataKabkota: [],
    dataKabkotafilter:[],
    renderCheckSyarat:"none",
    check_syarat:"",
    kategori_pendaftar:"",
    kode_member:"",
    code_recommender:"",
    dataMember:[],
    disabled:true,
    color:"grey",
    sumber:"",
    aktifKonten:[],
    dataQuestion:[],
    pilihan:[
      {
        "value": 1,
        "text": "Ya",
      },
      {
        "value": 0,
        "text": "Tidak",
      }
    ],
    checkemail:"",
    pesanEmailError:"",
    dataBizDev: [],
    errors: "",
    password: "",
    showPassword: false,
    username: ""
  } 

  handleChange = (e) => {
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  sendData = (e) => {
    e.preventDefault();

    this.setState({
      btnDisabled: true,
      btnText: 'Sending ...'
    })

    var formData = new FormData();
    formData.append('name',this.state.nama_lengkap);
    formData.append('phone',this.state.no_telp);
    formData.append('email',this.state.email);
    formData.append('province',this.state.id_propinsi);
    formData.append('kabupaten',this.state.id_kabkota);
    formData.append('sumber_informasi',this.state.sumber);
    formData.append('kategori_pendaftar',this.state.kategori_pendaftar);
    formData.append('nama_pimpinan',this.state.nama_pimpinan);
    formData.append('jabatan',this.state.jabatan);
    formData.append('alamat',this.state.alamat);
    formData.append('code_recommender',this.state.code_recommender);
    formData.append('code_roles','RL008');
    formData.append('username',this.state.username);
    formData.append('password',this.state.password);

    axios.post(API_URL.superStudent+'/ambassador/registration', formData, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${API_URL.ambassadorKey}`
      }
    })
    .then(response => {
      if (response.status == '200') {
        Swal.fire({
          icon: 'success',
          title: 'Your data has been saved',
          showConfirmButton: false,
          timer: 5000
        })
        window.location.reload(false)
      } else {
        this.setState({
          showSalError: true,
          message: response.data.message,
          btnDisabled: false,
          btnText: 'Send',
        })
      }
    })
    .catch(err => {
      if(err.response.status === 422 || err.response.status === 409){
        this.setState({
          errors:err.response.data.data,
          showSalError: true,
          message: err.response.data.data.email[0],
          btnDisabled: false,
          btnText: 'Send',
        })
      }
    })
  }

  onValueChange(e) {
    this.setState({
      kategori_pendaftar: e.target.value
    },() => {
      this.setState({
        formInstitution: this.state.kategori_pendaftar === '2' ? '' : 'none'
      })
    })
  }

  getDataPropinsi(){
    const url = API_URL.superStudent+'/api/v1/application/master/provinces';
      axios.get(url).then(response => response.data.data)
      .then((data) => {
          this.setState({ dataPropinsi: data })
      })
  }

  getDataKabkota(){
    const url = API_URL.superStudent+'/api/v1/application/master/kabupaten';
    axios.get(url).then(response => response.data.data)
    .then((data) => {
        this.setState({ dataKabkota: data })
    })
  }

  getBizdev(){
    const url = API_URL.superStudent+'/ambassador/consultant/jobdesk';
    let formData = new FormData ()
    formData.append('jobdesk', JSON.stringify(['JD007','JD016']))
    axios.post(url, formData, {
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${API_URL.ambassadorKey}`
        }
    }).then(response => response.data.data)
    .then(result => this.setState({ dataBizDev: result}))
    .catch(error => console.log(error))    
  }

  setPropinsi = (e) => {
      this.setState({
          id_propinsi:e.target.value
      })

      const data = Object.values(this.state.dataKabkota);
      const id = e.target.value;
      const filterData = data.filter(dataFilter => {
        return dataFilter.province_id == id;
        });
      this.setState({dataKabkotafilter:filterData})
  }

  setCheckSyarat(e){
    e.preventDefault();
    this.setState({
      check_syarat:e.target.value
    })

    if(e.target.value === '0') {
      this.setState({
        disabled:true,
        color:"grey",
        renderCheckSyarat:"none"
      })
    }else if(e.target.value === '1'){
      this.setState({
        disabled:true,
        color:"grey",
        renderCheckSyarat:""
      })
    }else if(e.target.value === '2'){
      this.setState({
        disabled:false,
        color:"black",
        renderCheckSyarat:"none"
      })
    }
  }

  radioChangeHandler = (event) =>{
    this.setState({
        konsultan:event.target.value
    })
  }

  componentDidMount() {
    this.getBizdev();
    this.getDataPropinsi();
    this.getDataKabkota();
  }

  sPassword() {
    if (this.state.showPassword == false) {
      this.setState({
        showPassword: true
      })
    } else {
      this.setState({
        showPassword: false
      })
    }
  }

  renderCheckSyarat() {
    return(
      <div style={{display:this.state.renderCheckSyarat}}>
          <FormGroup style={{marginBottom:"30px"}}>
            <Label for="kategori_pendaftar" style={{ color: 'black', fontWeight:'500'}}>Kategori Pendaftar</Label>
              <select md="3" className="form-control" name="kategori_pendaftar" placeholder=""
                onChange={(e) => this.onValueChange(e)}
                value={this.state.kategori_pendaftar}
              >
                <option value="0">-</option>
                <option value="1">Personal</option>
                <option value="2">Institution</option>
            </select>
          </FormGroup>
          <FormGroup style={{marginBottom:"30px"}}>
            <Label for="nama_lengkap" style={{ color: '#7A7A7A', fontWeight:'500'}}>Nama {this.state.kategori_pendaftar === '2' ? 'Instansi' : 'Lengkap'}</Label>
            <input md="3" className="form-control" name="nama_lengkap"  value={this.state.nama_lengkap} onChange={this.handleChange}/>
            <span style={{color: 'red'}}><strong>{this.state.errors.name}</strong></span>
          </FormGroup>
          <div style={{display: this.state.formInstitution}}>
            <FormGroup>
              <Label for="nama_pimpinan" style={{ color: '#7A7A7A', fontWeight:'500'}}>Nama Pimpinan</Label>
              <input md='3' className='form-control' name='nama_pimpinan' placeholder='Nama Pimpinan' value={this.state.nama_pimpinan} onChange={this.handleChange}/>
            </FormGroup>
            <FormGroup>
              <Label for="nama_lengkap" style={{ color: '#7A7A7A', fontWeight:'500'}}>Jabatan</Label>
              <input md='3' className="form-control" name="jabatan" placeholder="jabatan" value={this.state.jabatan} onChange={this.handleChange}/>
          </FormGroup>
          </div>
          <FormGroup style={{marginBottom:"30px"}}>
            <Label for="inputEmail" style={{ color: '#7A7A7A', fontWeight:'500'}}>Email</Label>
            <input md='3' className="form-control" type="email" placeholder="Email" name="email" onChange={this.handleChange} value={this.state.email}/>
            <span style={{color: 'red'}}><strong>{this.state.errors.email}</strong></span>
          </FormGroup>
          <FormGroup style={{marginBottom:"30px"}}>
            <Label for="no_wa" style={{ color: '#7A7A7A', fontWeight:'500'}}>Phone / Whatsapp</Label>
            <input className="form-control" type="number" name="no_telp" placeholder="Phone / WhatsApp" onChange={this.handleChange} value={this.state.no_telp}/>
            <span style={{color: 'red'}}><strong>{this.state.errors.phone}</strong></span>
          </FormGroup>
          <FormGroup style={{marginBottom:"30px"}}>
            <Label for="id_propinsi" style={{ color: '#7A7A7A', fontWeight:'500'}}>Provinsi</Label>
            <select md="3" className="form-control" name="id_propinsi"  placeholder="Propinsi" value={this.state.id_propinsi} onChange={this.setPropinsi}
              >
                <option value="0">--Pilih Propinsi--</option>
                {this.state.dataPropinsi.map((data1)=>(
                    <option key={data1.id} value={data1.id}>{data1.province_name}</option>
                ))}
            </select>
            <span style={{color: 'red'}}><strong>{this.state.errors.province}</strong></span>
          </FormGroup>
          <FormGroup style={{marginBottom:"30px"}}>
            <Label for="id_kabkota" style={{ color: '#7A7A7A', fontWeight:'500'}}>Kab / Kota</Label>
            <select md="3" className="form-control" name="id_kabkota" placeholder="Kab / Kota" value={this.state.id_kabkota} onChange={this.handleChange}>
              <option value="0">--Pilih Kab/Kota--</option>
              {this.state.dataKabkotafilter.map((data2)=>(
                <option key={data2.id} value={data2.id}>{data2.kabupaten_name}</option>
              ))}
            </select>
            <span style={{color: 'red'}}><strong>{this.state.errors.kabupaten}</strong></span>
          </FormGroup>
          <FormGroup>
            <Label for="nama_lengkap" style={{ color: '#7A7A7A', fontWeight:'500'}}>Alamat Lengkap</Label>
            <input md='3' className="form-control" name="alamat" placeholder="alamat" value={this.state.alamat} onChange={this.handleChange}/>
          </FormGroup>
          <FormGroup>
            <Label for="recomender" style={{ color: '#7A7A7A', fontWeight:'500'}}>Recomender</Label>
            <select className="form-control" name="code_recommender" onChange={this.handleChange} type="text" value={this.state.code_recommender}>
                <option value="0">--Pilih recommender--</option>
                {this.state.dataBizDev.map((data)=>(
                  <option key={data.code_konsultan} value={data.code_konsultan}>{data.nama}</option>
                ))}
            </select>
          </FormGroup>
          <FormGroup style={{marginBottom:"20px"}}>
            <Label for="sumber" style={{ color: 'black', fontWeight:'500'}}>Darimana Mengetahui NIEC</Label>
            <select md="3" className="form-control" name="sumber"  id="sumber" value={this.state.sumber} onChange={this.handleChange}>
              <option value="0">-</option>
              <option value="Sosial Media">Sosial Media</option>
              <option value="Website">Website</option>
              <option value="Staf NIEC">Staf NIEC</option>
              <option value="Google">Google</option>
              <option value="Ambassador Lainnya">Ambassador Lainnya</option>
            </select>
          </FormGroup>
          <FormGroup>
            <Label for="username" style={{ color: '#7A7A7A', fontWeight:'500'}}>Username</Label>
            <input md='3' className="form-control" name="username" placeholder="username" value={this.state.username} onChange={this.handleChange}/>
          </FormGroup>
          <FormGroup>
            <Label for="password" style={{ color: '#7A7A7A', fontWeight:'500'}}>Password</Label>
            <div className="row">
              <div className="col-sm-11">
                <input id="password-field" md='3' className="form-control" name="password" placeholder="password" type={this.state.showPassword ? 'text' : 'password'} value={this.state.password} onChange={this.handleChange}/>
              </div>
              <div className="col-sm-1">
                <span class="fa fa-fw fa-eye field-icon toggle-password" onClick={() => this.sPassword()}></span>
              </div>
            </div>
          </FormGroup>
      </div>
    );
  }

  render() {
    return (
      <>
        <LandingPageHeader  datakonten = {this.state.aktifKonten}/>
        <Info />
        {/* REGISTER SECTION */}
        <div className="main">
          <div className="section" id="register" style={{ backgroundColor: ''}}>
            <Container>
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <h1 className="section-title text-center" style={{fontWeight:"500"}}>REGISTER HERE</h1>
                    <div className="section-title-border margin-t-20"></div>
                </div>
              </div>
              <form>
                <Row style={{marginTop:"20px"}}>
                  <Col lg="6" md="12" style={{backgroundColor:"", paddingRight:"50px"}}>
                    <h2 className="section-title text-center margin-b-20 " style={{fontWeight:"500"}}>Syarat dan Ketentuan</h2>
                    <br />
                    <br />
                    <div>
                      <Label for="syarat" style={{ color: '#7A7A7A', fontWeight:'bold'}}>INFORMASI PENTING UNTUK NIEC GLOBAL AMBASADOR:</Label>
                      <p className="description" style={{ fontWeight: '500', color:'black' }}>Syarat dan kondisi dari perjanjian ini ditetapkan di bawah ini. 
                      Pastikan Anda telah membaca dan memahami kondisi sebelum masuk ke dalam perjanjian. 
                      Dengan menyetujui (klik OK pada halaman terakhir), maka kami menganggap bahwa Anda telah membaca dan memahami syarat NIEC Global Ambassador.</p>
                    </div>
                    <div>
                      <Label for="syarat" style={{ color: '#7A7A7A', fontWeight:'bold'}}>1. SYARAT MENJADI NIEC GLOBAL AMBASSADOR</Label>
                      <p className="description" style={{ fontWeight: '500', color:'black' }}>Untuk menjadi NIEC Global Ambassador dapat ditempuh dengan cara sebagai berikut</p>
                      <p className="description ml-3" style={{ fontWeight: '500', color:'black' }}>(1) 	Sudah berusia minimal 18 (depalan belas) tahun, yang dibuktikan dengan KTP (Kartu Tanda Penduduk). </p>
                      <p className="description ml-3" style={{ fontWeight: '500', color:'black' }}>(2) 	Mendaftar di website milik PIHAK PERTAMA, yaitu melalui: www.ambassador.niecindonesia.com</p>
                      <p className="description ml-3" style={{ fontWeight: '500', color:'black' }}>(3) 	Menandatangani Perjanjian Kerja Sama sebagai NIEC Global Ambassador.</p>
                      <br />
                    </div>
                    <div>
                      <Label for="syarat" style={{ color: '#7A7A7A', fontWeight:'bold'}}>2. TANGGUNG JAWAB NIEC GLOBAL AMBASSADOR</Label>
                      <p className="description" style={{ fontWeight: '500', color:'black' }}>
                      Tanggung jawab NIEC Global Ambassador adalah sangat mudah, cukup dengan:<br />
                      a. Merekomendasikan kenalan, teman, ataupun keluarga (nama & nomor telepon) yang ingin kuliah di luar negeri kepada NIEC.<br />
                      b. Program kuliah di luar negeri bisa dalam bentuk jenjang Diploma, Bachelor (S1), Master (S2), dan Ph.d. (S3).<br />
                      c. Siswa yang direkomendasikan ke NIEC harus terlebih dahulu melalui persetujuan dari yang bersangkutan.<br />
                      </p>
                      <br />
                    </div>
                    <div>
                      <Label for="syarat" style={{ color: '#7A7A7A', fontWeight:'bold'}}>3. HAK NIEC GLOBAL AMBASSADOR</Label>
                      <p className="description" style={{ fontWeight: '500', color:'black' }}>
                        a. Berhak menghubungi NIEC untuk mengetahui informasi perkembangan siswa yang direkomendasikan melalui info@niecindonesia.com atau ke 0812 3843 1234<br />
                        b. Berhak memperoleh insentif dan bonus lainnya.<br /></p>
                      <br />
                    </div>
                    <div>
                      <Label for="syarat" style={{ color: '#7A7A7A', fontWeight:'bold'}}>4. TANGGUNG JAWAB NIEC</Label>
                      <p className="description" style={{ fontWeight: '500', color:'black' }}>
                      a. Menfollow up data yang telah diberikan oleh NIEC Global Ambassador.<br />
                      b. Mengurus proses kuliah di luar negeri mulai dari konsultasi pemilihan jurusan, kampus, negara, LoA, document visa, homestay di luar negeri dll.<br />
                      c. Menginformasikan perkembangan siswa yang direkomendasikan jika NIEC Global Ambassador meminta.<br />
                      d. Memberikan insentif dan bonus kepada NIEC Global Ambassador sesuai dengan perjanjian yang nantinya akan disepakati.<br /></p>
                      <br />
                    </div>
                    <div>
                      <Label for="syarat" style={{ color: '#7A7A7A', fontWeight:'bold'}}>5. INSENTIF & BONUS</Label>
                      <p className="description" style={{ fontWeight: '500', color:'black' }}>
                      NIEC Global Ambassador akan mendapat insentif yang besarannya akan dijelaskan dalam perjanjian yang akan dikirim setelah anda melakukan registrasi ini.<br />
                      </p> 
                      <br />
                    </div>
                  </Col>
                  <Col lg="6" md="12" style={{backgroundColor:""}}>
                    <div  style={{ backgroundColor: '', paddingTop:"50px"}}>
                      <FormGroup style={{marginBottom:"0px"}}>
                        <Label for="check_syarat" style={{ color: 'black', fontWeight:'500'}}>Apakah setuju dengan syarat dan ketentuan disamping ini?</Label>
                        <select md="3" className="form-control" type="check_syarat"  id="check_syarat" placeholder=""
                          onChange={(e) => this.setCheckSyarat(e)}
                          ref={e => this.inputCheckMember = e}
                        >
                          <option value="0">-</option>
                          <option value="1">Setuju</option>
                          <option value="2">Tidak</option>
                        </select>
                        <label className="error-label-register">
                          {this.state.error.check_syarat}
                        </label>
                      </FormGroup>
                      {this.renderCheckSyarat()}
                    </div>
                    <Col md="12">
                      <Button disabled={this.state.btnDisabled} className="text-center btn btn-alert btn-custom" color="success" type="submit"  onClick={e=>this.sendData(e)}>{this.state.btnText}</Button>
                    </Col>
                  </Col>
                </Row>
              </form>
            </Container>

            <SweetAlert
              danger
              show={this.state.showSalError}
              title="Register Failed"
              onConfirm={() => {
                this.setState({
                  showSalError: false,
                  btnDisabled: false,
                  btnText: 'Send',
                })
              }}
            >
              {this.state.message}
            </SweetAlert>
          </div>
        </div>
        {/* REGISTER SECTION */}
        <DemoFooter />
      </>
    );

  }
}

export default LandingPage;
