import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';

import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";

// Styles
import './assets/css/bootstrap.min.css';
import "./assets/css/themify-icons.css";
import './assets/scss/paper-kit.scss';
import './assets/demo/demo.css';

import { LandingPage, Home, GlobalAmbasador } from "./containers/pages"

class App extends Component {
  render() { 
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={LandingPage} />
          <Route path='/r/' component={() => { 
            window.location.href = '#register'; 
            return null;
          }}/>
        </Switch>
        <Switch>
        <Route path="/:username" exact component={GlobalAmbasador} />
        </Switch>
      </Router>
    );
      }
}

export default App;
