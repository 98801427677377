import React from 'react';
// import Benefit from './Benefit';

class Info extends React.Component {
  render() {
  	return (
        <section className="section pt-5" id="info-page" style={{backgroundColor:""}}>
            <div className="container">
                <div className="row margin-t-30">
                    <div className="col-lg-6 col-md-12">
                        <div className="col-lg-12">
                            <h2 className="section-title text-center margin-b-20" style={{fontWeight:"500"}}>Apa itu NIEC
                            Global Ambassador?</h2>
                            <div className="section-title-border mb-5"></div>
                            <p className="description" style={{ fontWeight: '500', color:'black' }}>
                            NIEC Global Ambassado adalah orang atau lembaga yang memiliki keinginan untuk membantu, keluarga, teman, siswa atau kenalan
                            untuk bisa kuliah di luar negri<br /><br />
                            </p>
                            <p className="description" style={{ fontWeight: '500', color:'black' }}>
                             Naresy International Education Consultant (NIEC) adalah konsultan pendidikan dan visa yang berpusat di Bali dengan kantor cabang di Yogyakarta,
                            yang telah menjadi pusat informasi terpercaya bagi siswa selama 11 tahun untuk pendaftaran belajar, study tour, sister school, homestay, dan training internasional ke Australia, New Zealand, Canada, UK, USA, Europe, Korea & Singapore. 
                            Kunjungi niecindonesia.com untuk informasi lengkap kuliah di luar negeri atau follow Instagram @niec_indonesia</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                            <h2 className="section-title text-center mb-5" style={{fontWeight:"500"}}>Benefit</h2>
                            
                            <p className="description" style={{ fontWeight: '500', color:'black' }}> 
                            <img src={require('./../../../assets/img/logo_ilustrasi_bennefit/icon-01.png')} className="benefit-icon" alt="" />
                            &nbsp; Insentif jutaan rupiah secara langsung tanpa diundi.</p><br />
                            <p className="description" style={{ fontWeight: '500', color:'black' }}>
                            <img src={require('./../../../assets/img/logo_ilustrasi_bennefit/icon-03.png')} className="benefit-icon" alt=""/>
                            &nbsp; Kesempatan untuk travel ke luar negeri.</p><br />
                            <p className="description" style={{ fontWeight: '500', color:'black' }}>
                            <img src={require('./../../../assets/img/logo_ilustrasi_bennefit/icon-04.png')} className="benefit-icon" alt=""/>
                            &nbsp; Undangan khusus untuk menghadiri acara “Appreciation Night”.</p><br />
                            <p className="description" style={{ fontWeight: '500', color:'black' }}>
                            <img src={require('./../../../assets/img/logo_ilustrasi_bennefit/icon-05.png')} className="benefit-icon" alt=""/>
                            &nbsp; Dan berbagai macam hadiah/bonus menarik lainnya.</p><br />

                            
                    </div>
                </div>
            </div>
            {/* <Benefit /> */}
        </section>
        

  	);
  }
}
export default Info;