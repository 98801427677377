import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";

class Footer extends Component {
    state = {
        links1 : [
            { link : "https://niecindonesia.com/", title : "Website" },
            { link : "http://prioritas.niecindonesia.com/", title : "Prioritas Member" },
            { link : "http://ambassador.niecindonesia.com/", title : "Global Ambassador" }
        ],
        socials : [
            { class : "bg-twitter", icon : "ti-twitter-alt", link : "https://twitter.com/NIEC_Indonesia" },
            { class : "bg-dribbble", icon : "ti-instagram", link : "https://www.instagram.com/niec_indonesia/" },
            { class : "bg-googleplus", icon : "ti-youtube", link : "https://www.youtube.com/channel/UCA5qiOgJdfMvADpyU7Oz7fQ" },
            { class : "bg-facebook", icon : "ti-facebook", link : "https://www.facebook.com/Naresy-International-Education-Consultant-578610318865001" },
        ]
    }

    render() {
        return (
            <footer className="footer-new bg-dark2 font-footer">
                <Container>
                    <Row>
                        <Col sm="12" md="8">
                            <h1 className="margin-t-20">Follow Us On</h1>
                            <ul className="list-inline social">
                                {
                                    this.state.socials.map((social, key) =>
                                        <li key={key} className="list-inline-item">
                                            <a className={social.class + " mr-1"} rel='noreferrer' href={social.link} target='_blank'><i className={social.icon}></i></a>
                                        </li>
                                    )
                                }
                            </ul>
                            <h4 className="margin-t-30">2022 | <span className='font-default'>NIEC Indonesia</span></h4>
                        </Col>
                        <Col sm="12" md='4' >
                            <h3>More Information</h3>
                            <Row>
                                <Col md="2" style={{fontWeight: '600'}}>Phone</Col>
                                <Col md="1">:</Col>
                                <Col md="8" style={{color: '#b28b3d', fontWeight: '600'}}>0812-3843-1243 (Windri)</Col>
                            </Row>
                            <Row>
                                <Col md="2" style={{fontWeight: '600'}}>Email</Col>
                                <Col md="1">:</Col>
                                <Col md="8" style={{color: '#b28b3d', fontWeight: '600'}}>bizdev@niecindonesia.com</Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}
export default Footer;